import { registerApplication, start } from 'single-spa'
import {
  constructRoutes,
  constructApplications,
  constructLayoutEngine,
} from 'single-spa-layout'

import { hotjarInit } from './helpers/hotjar'

const routes = constructRoutes(document.querySelector('#single-spa-layout'), {
  loaders: {
    reactRouteLoader: '<h1>Loading react single route...</h1>',
    transition: '<h1>App transition...</h1>',
  },
  errors: {
    topNav: '<h1>Failed to load topnav</h1>',
  },
  props: {},
})
const applications = constructApplications({
  routes,
  loadApp: ({ name }) => System.import(name),
})

constructLayoutEngine({
  routes,
  applications,
})

applications.forEach(registerApplication)

hotjarInit()

start()
