/* eslint-disable */
export function hotjarScript() {
  ;(function (h, o, t, j, a, r) {
    let id = 2796544 // prod
    if (
      document.location.host.includes('latest.frankiefinancial.io') ||
      document.location.host.includes('localhost')
    ) {
      id = 3453346
    }
    if (document.location.host.includes('qa.frankiefinancial.io')) {
      id = 3453345
    }
    if (document.location.host.includes('kycaml.uat.frankiefinancial.io')) {
      id = 3453340
    }
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid: id, hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
    console.log('Hotjar inited')
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}
